import { Routes } from '@angular/router';
import { IsExternalUserGuard } from './core/guards/is-external-user.guard';
import { IsLoggedOutGuard } from './core/guards/is-logged-out.guard';
import { IsLoggedGuard } from './core/guards/isLogged.guard';

const title: string = 'Balcão Online';

export const routes: Routes = [
  {
    path: '',
    title: title,
    canActivate: [IsLoggedOutGuard],
    loadComponent: () =>
      import('./front-office/layout/layout.component').then(
        m => m.LayoutComponent
      ),
    children: [
      {
        path: '',
        title: title,
        loadComponent: () =>
          import('./front-office/home/home.component').then(
            m => m.HomeComponent
          ),
      },
      {
        path: 'about',
        title: title,
        loadComponent: () =>
          import('./about/about.component').then(m => m.AboutComponent),
      },
      {
        path: 'register',
        title: title,
        children: [
          {
            path: 'company',
            title: title,
            loadComponent: () =>
              import(
                './front-office/register/register-company/register-company.component'
              ).then(m => m.RegisterCompanyComponent),
          },
          {
            path: 'artisanal-user',
            title: title,
            loadComponent: () =>
              import(
                './front-office/register/register-artisanal-user/register-artisanal-user.component'
              ).then(m => m.RegisterArtisanalUserComponent),
          },
        ],
      },
      {
        path: 'companies',
        title: title,
        loadComponent: () =>
          import('./front-office/login/login.component').then(
            m => m.LoginComponent
          ),
      },
      {
        path: 'announcements',
        title: 'Notícias',
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                './front-office/announcements/announcements.component'
              ).then(m => m.AnnouncementsComponent),
          },
          {
            path: 'id/:id',
            loadComponent: () =>
              import(
                './front-office/announcements/announcements.component'
              ).then(m => m.AnnouncementsComponent),
          },
          {
            path: 'tag/:tag',
            loadComponent: () =>
              import(
                './front-office/announcements/announcements.component'
              ).then(m => m.AnnouncementsComponent),
          },
        ],
      },
      {
        path: 'login/users',
        title: 'Login',
        loadComponent: () =>
          import('./front-office/login/login.component').then(
            m => m.LoginComponent
          ),
      },
      {
        path: 'login/companies',
        title: 'Login',
        loadComponent: () =>
          import('./front-office/login/login.component').then(
            m => m.LoginComponent
          ),
      },
    ],
  },
  {
    path: 'b',
    title: 'Dashboard',
    canActivateChild: [IsLoggedGuard],
    loadComponent: () =>
      import('./back-office/layout/layout.component').then(
        m => m.LayoutComponent
      ),
    children: [
      {
        path: 'wip',
        title: 'Página em desenvolvimento',
        loadComponent: () =>
          import('./back-office/wip/wip.component').then(m => m.WipComponent),
      },
      {
        path: 'dashboard',
        title: 'Dashboard',
        loadComponent: () =>
          import('./back-office/dashboard/dashboard.component').then(
            m => m.DashboardComponent
          ),
      },
      {
        path: 'processes',
        title: 'Lista de Processos',
        loadComponent: () =>
          import(
            './back-office/processes/processes-list/processes-list.component'
          ).then(m => m.ProcessesListComponent),
      },
      {
        path: 'process-management',
        title: 'Gestão do Processo',
        loadComponent: () =>
          import(
            './back-office/processes/process-management/process-management.component'
          ).then(m => m.ProcessManagementComponent),
      },
      {
        path: 'profile',
        title: title,
        loadComponent: () =>
          import('./back-office/profile/profile.component').then(
            m => m.ProfileComponent
          ),
      },
      {
        path: 'process-requests',
        title: title,
        canActivate: [IsExternalUserGuard],
        loadComponent: () =>
          import(
            './back-office/processes/process-requests/process-requests.component'
          ).then(m => m.ProcessRequestsComponent),
      },
      {
        path: 'process-requests/tdcpi',
        title: 'Pedido do Título de Concessão de Direitos de Pesca',
        loadComponent: () =>
          import(
            './back-office/processes/process-requests/tcdp-request-form/tcdp-request-form.component'
          ).then(m => m.TcdpRequestFormComponent),
      },
      {
        path: 'process-requests/cdpi',
        title: 'Pedido do Certificado de Pesca',
        loadComponent: () =>
          import(
            './back-office/processes/process-requests/cdpi-request-form/cdpi-request-form.component'
          ).then(m => m.CdpiRequestFormComponent),
      },
      {
        path: 'external-users-management',
        title: 'Gestão de utentes',
        loadComponent: () =>
          import(
            './back-office/management/external-users-management/external-users-management.component'
          ).then(m => m.ExternalUsersManagementComponent),
      },
      {
        path: 'internal-users-management',
        title: 'Gestão de Utilizadores',
        loadComponent: () =>
          import(
            './back-office/management/internal-users-management/internal-users-management.component'
          ).then(m => m.InternalUsersManagementComponent),
      },
      {
        path: 'departments-management',
        title: 'Gestão de Departamentos',
        loadComponent: () =>
          import(
            './back-office/management/departments-management/departments-management.component'
          ).then(m => m.DepartmentsManagementComponent),
      },
      {
        path: 'announcements',
        title: 'Notícias',
        loadComponent: () =>
          import('./back-office/announcements/announcements.component').then(
            m => m.AnnouncementsComponent
          ),
      },
      {
        path: '**',
        redirectTo: '/b/dashboard',
      },
    ],
  },
  {
    path: 'verify-email',
    title: title,
    loadComponent: () =>
      import('./verify-email/verify-email.component').then(
        m => m.VerifyEmailComponent
      ),
  },
  {
    path: 'forgot-password',
    title: title,
    loadComponent: () =>
      import('./forgot-password/forgot-password.component').then(
        m => m.ForgotPasswordComponent
      ),
  },
  {
    path: 'reset-password',
    title: title,
    loadComponent: () =>
      import('./reset-password/reset-password.component').then(
        m => m.ResetPasswordComponent
      ),
  },
  {
    path: '**',
    redirectTo: '',
  },
];
